<template>
  <v-card class="mt-2 ml-2 mr-2">
    <!--    <v-toolbar-->
    <!--        flat-->
    <!--        light-->
    <!--    >-->
    <!--      <v-toolbar-title>{{ tabTitle }}</v-toolbar-title>-->
    <!--    </v-toolbar>-->

    <v-tabs
      :vertical="$vuetify.breakpoint.mdAndUp"
      :centered="$vuetify.breakpoint.smAndDown"
      color="deep-purple accent-4"
      background-color="#F5F5FF5F"
    >
      <v-tab class="justify-md-start" disabled>
        <v-icon left> mdi-file-tree </v-icon>
        Struktur Organisasi
      </v-tab>

      <v-tab class="justify-start" @click="tabCompanyClick"> KAB/KOTA </v-tab>
      <v-tab class="justify-start" @click="tabDivisionClick"> DINAS/OPD </v-tab>

      <!--      <v-tab class="justify-start" @click="tabDivisionClick">-->
      <!--        PEGAWAI-->
      <!--      </v-tab>-->

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>Application Setting</p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FCompanyTable ref="refCompanyTable" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FDivisionTable ref="refDivisionTable" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FPegawaiTable ref="refPegawaiTable" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import FCompanyTable from "../../components/setting/FCompanyTable";
import FDivisionTable from "../../components/setting/FDivisionTable";
import FPegawaiTable from "@/components/setting/FPegawaiTable";

export default {
  name: "StrukturOrganisasi",
  components: { FCompanyTable, FDivisionTable, FPegawaiTable },
  data() {
    return {
      tabTitle: "Sistem Setting",
    };
  },
  methods: {
    tabUsersClick() {
      // this.$refs.refUsersTable.fetchParent()
    },
    tabCompanyClick() {
      // this.$refs.refAsosiasiTable.fetchParent()
    },
    tabDivisionClick() {
      // this.$refs.refDivisionTable.fetchParent()
    },
    tabSalesmanClick() {
      // this.$refs.refPegawaiTable.fetchParent()
    },
  },
};
</script>

<style scoped>
</style>