<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >
      <!--      scrollable-->

      <v-card>
        <v-form v-model="valid" ref="form">
          <v-toolbar dark color="brown" class="color-gradient-1" dense>
            <v-btn icon dark @click="closeForm">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>Staff/Pegawai</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="save"
                :disabled="!valid || isItemModified === false"
                class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-title>
            <v-container class="pa-4 text-center">
              <v-row class="fill-height" align="center" justify="center">
                <template>
                  <v-col cols="12" md="4">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :elevation="hover ? 10 : 1"
                        :class="[
                          { 'on-hover': hover },
                          hover
                            ? 'card-hover-opacity'
                            : 'card-not-hover-opacity',
                        ]"
                      >
                        <v-img
                          :lazy-src="lookupImageUrlLazy(itemModified)"
                          :src="lookupImageUrl(itemModified)"
                          height="225px"
                        >
                          <v-card-title class="text-h6 white--text fill-height">
                            <v-row
                              class="fill-height flex-column"
                              justify="space-between"
                            >
                              <v-spacer />
                              <div class="align-self-center">
                                <v-btn
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  icon
                                  large
                                  dark
                                  outlined
                                  @click="showDialogUpload"
                                >
                                  <v-icon
                                    :class="{ 'show-btns': hover }"
                                    :color="transparent"
                                    large
                                  >
                                    mdi-upload
                                  </v-icon>
                                </v-btn>
                              </div>
                            </v-row>
                          </v-card-title>
                        </v-img>
                      </v-card>
                    </v-hover>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-card-title>

          <v-card-title class="text-uppercase grey--text">
            <span v-if="formMode === 'EDIT_FORM'">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM'"> BARU</span>
            <v-chip
              class="ma-2"
              color="warning"
              outlined
              x-small
              v-show="isItemModified"
            >
              <v-icon left> mdi-pencil </v-icon>
              modfied
            </v-chip>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    v-model="itemModified.description"
                    label="Nama"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="itemModified.kode1"
                    :rules="rulesLenght"
                    label="NIP"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2"> </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="7" md="7">
                  <v-autocomplete
                    v-model="itemModified.fdivisionBean"
                    :items="itemsFDivision"
                    :rules="rulesNotEmtpy"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    dense
                    chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    label="Dinas/OPD"
                    hint="Dinas/OPD"
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-switch
                  v-model="itemModified.statusActive"
                  :label="itemModified.statusActive ? 'Aktif' : 'Non-Aktif'"
                  x-small
                  class="pa-3"
                ></v-switch>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="itemModified.golongan"
                    label="Golongan"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="9" md="9">
                  <v-text-field
                    v-model="itemModified.jabatan"
                    label="Jabatan"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    v-model="itemModified.address"
                    label="Alamat"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="itemModified.city"
                    label="Kota"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="7" md="7">
                  <v-text-field
                    prepend-inner-icon="mdi-email"
                    v-model="itemModified.email"
                    label="Email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field
                    prepend-inner-icon="mdi-phone"
                    v-model="itemModified.phone"
                    label="Phone"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-chip
              class="ml-4"
              color="error"
              outlined
              close
              small
              v-show="formDialogOptions.errorMessage"
            >
              {{ formDialogOptions.errorMessage }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="closeForm"
              class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="save"
              :disabled="!valid || isItemModified === false"
              class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <close-confirm-dialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></close-confirm-dialog>

      <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
      >
      </UploadImageDialog>
      <v-snackbar v-model="snackbar">
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import FPegawaiService from "@/services/apiservices/f-pegawai-service";

import CloseConfirmDialog from "@/components/utils/CloseConfirmDialog";
import FormMode from "@/models/form-mode";
import FPegawai from "@/models/f-pegawai";
import FileService from "@/services/apiservices/file-service";
import UploadImageDialog from "@/components/utils/UploadImageDialog";

export default {
  name: "UsersDialog",
  components: { CloseConfirmDialog, UploadImageDialog },
  props: {
    formMode: String,
  },
  data() {
    return {
      snackBarMesage: "",
      snackbar: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: "",
      itemModified: new FPegawai(0, "", ""),
      selectFDivision: { id: 0, kode1: "", description: "" },
      itemsFDivision: [
        { id: 1, kode1: "", description: "" },
        { id: 2, kode1: "", description: "" },
      ],

      valid: false,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],

      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
  },
  watch: {},
  methods: {
    showDialogUpload() {
      if (
        this.itemModified.description !== undefined &&
        this.itemModified.fdivisionBean !== undefined
      ) {
        if (this.itemModified.id === 0) {
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly();
        }
        this.$refs.refUploadDialog.showDialog();
      } else {
        this.snackBarMesage = "Deskripsi dan Divisi harus diisi dahulu";
        this.snackbar = true;
      }
    },

    completeUploadSuccess: function (val) {
      // console.log(val)
      //Hapus Dulu yang lama
      // console.log(this.itemModified.avatarImage)
      FileService.deleteImage(this.itemModified.avatarImage).then(
        () => {
          // console.log(response.data)
        },
        (error) => {
          console.log(error.response);
        }
      );

      if (val.fileName !== "") {
        // console.log(val.fileName)
        // console.log(val.description)
        // let fkegiatanBean = new FtRegistrasiFile(
        //     0,
        //     this.itemModified.id,
        //     val.fileName,
        //     val.description,
        //     "IMAGE",
        //     1,
        //     true
        // )
        //
        // FtRegistrasiFileService.deleteFKegiatanFileAvatarByParent(this.itemModified.id).then(
        //     ()=>{
        //
        //     },
        //     error=>{
        //       console.log(error.message)
        //     }
        // )
        // FtRegistrasiFileService.createFKegiatanFileAvatar(fkegiatanBean).then(
        //     response=>{
        //       // console.log(response)
        //       this.$refs.refUploadDialog.closeDialog()
        //       this.itemModified.avatarImage = response.data.fileName
        //     },
        //     error=>{
        //       console.log(error.message)
        //     }
        // )
        this.$refs.refUploadDialog.closeDialog();
        this.itemModified.avatarImage = val.fileName;
        this.saveUpdateOnly();
      }
    },

    showDialog(selectedIndex, item, itemsFDivision) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow;
      if (selectedIndex > -1) {
        this.selectedIndex = selectedIndex;
        this.initializeEditMode(item);
      } else {
        (this.itemDefault = new FPegawai(0, "", "")),
          (this.itemModified = new FPegawai(0, "", "")),
          (this.selectedIndex = -1);
      }

      this.itemsFDivision = itemsFDivision;
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    save() {
      if (this.isItemModified === false) {
        //Close aja
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
        return;
      }
      if (this.$refs.form.validate()) {
        if (this.formMode === FormMode.EDIT_FORM) {
          FPegawaiService.updateFPegawai(this.itemModified).then(
            () => {
              // console.log(response.data)
              this.$emit("eventFromFormDialogEdit", this.itemModified);
            },
            (error) => {
              // console.log(error);
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        } else {
          FPegawaiService.createFPegawai(this.itemModified).then(
            (response) => {
              this.$emit("eventFromFormDialogNew", response.data);
            },
            (error) => {
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        }
      }
    },
    saveCreateOnly() {
      FPegawaiService.createFPegawai(this.itemModified).then(
        (response) => {
          /**
           * dipaksa Save dan Update Dahulu
           */
          this.initializeEditMode(response.data);
          this.formMode = FormMode.EDIT_FORM;
        },
        (error) => {
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    saveUpdateOnly() {
      FPegawaiService.updateFPegawai(this.itemModified).then(
        () => {},
        (error) => {
          // console.log(error);
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
    initializeEditMode(item) {
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = "";

      FPegawaiService.getFPegawaiById(item.id).then(
        (response) => {
          // console.log(response.data)
          this.itemDefault = Object.assign({}, response.data);
          this.itemModified = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/account_icon.png";
      } else {
        return FileService.image_url_medium(item.avatarImage);
      }
    },
    lookupImageUrlLazy() {
      return "./assets/images/account_icon.png";
    },
  },
};
</script>

<style scoped>
.card-hover-opacity {
  opacity: 0.4;
}
.card-not-hover-opacity {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>